jQuery(window).on("load", function() {
    'use strict';
    // Foreground images slider
    if (!!jQuery('.js-slick--foreground').length) {
        // Full width slick sliders
        jQuery('.js-slick--foreground').slick({
            adaptiveHeight: false,
            arrows: true,
            autoplay: true,
            autoplaySpeed: 6000,
            dots: false,
            fade: true,
            infinite: true
        });
    }

});
