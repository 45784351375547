// Navbar toggle
let opened = null
const toggleVisibility = e => e.classList.toggle('is-open')
const handleDropdown = e => {
  const clickedItem = e
  toggleVisibility(clickedItem)
  if (!opened) {
    opened = clickedItem
  } else if (opened == clickedItem) {
    opened = null
  } else {
    toggleVisibility(opened)
    opened = clickedItem
  }
}

// Hide dropdown if clicked outside element
const handleClick = e => {
  if (e.target.className.includes('c-navbar__dropdown')) {
    handleDropdown(e.target)
  } else if (opened) {
    toggleVisibility(opened)
    opened = null
  }
}
document.addEventListener('click', handleClick)

// Hamburger toggle
document.querySelector('.js-hamburger').onclick = function(){
    document.querySelector('.c-navbar').classList.toggle("hamburger-opened");
    document.querySelector('.c-hamburger').classList.toggle("is-active");
}
