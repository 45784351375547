/**
 * Close modal function
*/
function closeModal(modal) {
    'use strict';
    var modal = jQuery(modal).parents('.js-modal');
    modal.removeClass('is-open');
}

/**
 * jQuery runtime
 */
jQuery(window).on("load", function(event) {
    'use strict';
    /**
     * Modal setup and opening
     */
    var modalTrigger    = jQuery('.js-modalTrigger'),
        modalClose      = jQuery('.js-modalClose');

    modalTrigger.on('click', function() {
        var self = jQuery(this),
            modalTitle = self.data('modal'),
            modal = jQuery('.js-modal[title="' + modalTitle + '"]');

        modal.addClass('is-open');
        event.preventDefault();
    });

    /**
     * Close button
     */
    modalClose.on('click', function() {
        closeModal( jQuery(this) );
    });

    /**
     * Clicking outside the modal to close
     */
    jQuery(".js-modalInner").click(function(event) {
        if(event.target == this) closeModal( jQuery(this) );
    });

});
